import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';


// Import echarts

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

import { apiServerUrl, convertDateStringToJsCompliant } from '../../config'
import { getEChartsOptionLineChart } from 'helpers/nag-echart-utils'

function NagLocationStats({ locationId, locationName }) {
  const [showChart, setShowChart] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [chartTitle, setChartTitle] = useState("")
  const [echartsSalesData, setEChartsSalesData] = useState(null);

  useEffect(() => {
    fetchLocationStats();
  }, [locationId]);

  const convertJsonToEChartsFormat = (data) => {
    // converts to the expected Echarts format
    let x_arr = []
    let y_arr = []
    for (const [k, v] of Object.entries(data)) {
      x_arr.push(k);
      y_arr.push(v['amount'] / 100.0);
    }
    return [x_arr, y_arr]
  }

  const fetchLocationStats = () => {
    fetch(apiServerUrl() + '/v0/merchant/location/' + locationId + '/stats/', {
      'credentials': 'include',
    })
      .then(response => response.json())
      .then(data => {
        if ("error" in data) {
          setErrorMessage(locationName + " stats not yet available");
          return;
        }
        setShowChart(true);
        setChartTitle(locationName + " Sales")
        setEChartsSalesData(convertJsonToEChartsFormat(data["aggregates"]));
      })
      .catch(error => {
        setErrorMessage("Something went wrong");
        console.error(error);
      });
  }

  return (
    <Row>
      <Col>
        {showChart &&
          <ReactEChartsCore
            echarts={echarts}
            option={getEChartsOptionLineChart(echartsSalesData, 'Sales $')}
            style={{ height: '18.75rem' }}
          />
        }
        {errorMessage &&
          <p className="text-muted">
            {errorMessage}
          </p>
        }
      </Col>
    </Row>
  );
}


NagLocationStats.propTypes = {
  locationId: PropTypes.number.isRequired,
  locationName: PropTypes.string.isRequired
};

export default NagLocationStats;
