import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkingContext } from 'context/Context';
import { isAuthed, apiServerUrl } from 'config'

const LinkingProvider = ({ children }) => {
    const [isLinked, setIsLinked] = useState(false);
    const [linkedLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isAuthed()) {
            getStatus();
        }
    }, []);

    const getStatus = () => {
        setLoading(true)
        fetch(apiServerUrl() + '/v0/user/status', {
            'credentials': 'include',
        })
        .then(response => {
            if (!response.ok) {
              // make the promise be rejected if we didn't get a 2xx response
              throw new Error("Not 2xx response", { cause: response });
            } else {
              // got the desired response
              return response.json()
            }
          })
            .then(data => {
                setLoading(false);
                if (data["message"] === "ok") {
                    setIsLinked(true);
                }
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
            });
    };

    return (
        <LinkingContext.Provider value={{ isLinked, linkedLoading, setIsLinked }}>
            {children}
        </LinkingContext.Provider>
    );
};

export default LinkingProvider;
LinkingProvider.propTypes = {
    children: PropTypes.node.isRequired
};