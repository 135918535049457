import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg2 from 'assets/img/generic/bg-2.jpg';
import dashboardDark from 'assets/img/nag/dashboard-dark.jpg';
import dashboard from 'assets/img/nag/dashboard-light.jpg';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import gtag from 'ga-gtag'

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const demoAction = () => {
    gtag('event', 'conversion', { 'send_to': 'AW-11314551242/3qahCLvB0dcYEMqzmZMq' });
    window.location.replace("/selectpos");
  }

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg2}
      position="center bottom"
      overlay
      data-testid="hero-element"
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          {/* <Button
            as={Link}
            variant="outline-danger"
            className="mb-4 fs--1 border-2 rounded-pill"
            to="#!"
          >
            <span className="me-2" role="img" aria-label="Gift">
              🎁
            </span>
            Become a pro
          </Button> */}
          <h1 className="text-white fw-light">
            Bring {' '}
            <span className="fw-bold">
              <Typewriter
                words={['clarity', 'efficiency', 'revenue']}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            to your business.
          </h1>
          <p className="lead text-white opacity-75">
            AI generated insight into missed sales and wasted product with specific guidance to optimizing your revenue.
          </p>
          <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={demoAction}
          >
            Try free today!
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-center mt-1 mt-xl-0 mb-4"
        >
          {/* <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link> */}
          <div style={{ 
                        maxWidth: '100%', 
                        overflow: 'hidden', 
                        position: 'relative',
                        paddingTop: '56.25%', /* Aspect ratio: 16:9 (height / width * 100) */
                        }}>
            <iframe 
              style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%',
                borderRadius: '10px', 
              }}
              src="https://www.youtube.com/embed/SSFwq2ZvpOs?si=ScTQAsQlxExFtn-D" 
              title="Not/a/guess Overview" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
