import React, {useContext} from 'react';
import { LocationContext } from 'context/Context';
import {Form} from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';

const LocationDropdown = () => {
    const {locations, selectedLocation, setSelectedLocation} = useContext(LocationContext);
    const updateSelectedLocation = (e) => {
        let newLocation = locations[e.target.selectedIndex];
        setSelectedLocation(newLocation);
      };
  return (
    <Form.Select size="sm" className="m-2" onChange={(e) => { updateSelectedLocation(e) }} >
        {isIterableArray(locations) && selectedLocation &&
            <>
            {locations.map((location, index) => (
                <option key={index} value={location.ID} selected={location.ID === selectedLocation.ID}>
                    {location.Name}
                </option>
            ))}
            </>
        }
    </Form.Select>
  );
};

export default LocationDropdown;          
          
          

