import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section bg="light" >
    <SectionHeader
      title="Inventory prediction and optimization"
      subtitle="We envision a future where small businesses thrive through data-driven decisions, reduce waste, and foster economic growth. Our mission is to be the catalyst that empowers these businesses to embrace innovation and achieve unprecedented heights in inventory management excellence."
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process key={index} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;
