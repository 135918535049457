import cookies from 'js-cookie'

/* Falcon configs */
export const version = '4.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle:'inverted'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };

/* Notaguess configs */
export function authorizeUrl() {
  return apiServerUrl() + "/v0/oauth/local/login"
}

export function authorizeSquareUrl() {
  return apiServerUrl() + "/v0/user/square/authorize"
}

export function apiServerUrl() {
  if (process.env.REACT_APP_NOTAGUESS_DEPLOY == 'local') {
      return "http://" + hostDomain()
  } else if (process.env.REACT_APP_NOTAGUESS_DEPLOY == 'sandbox') {
    return "https://sandbox.api." + hostDomain()
}
  return "https://api." + hostDomain()
}

export function hostDomain() {
  return process.env.REACT_APP_NOTAGUESS_API_HOST;
}

export function cookieDomain() {
  if (process.env.NODE_ENV !== 'production') {
      return hostDomain()
  } else {
      return "." + hostDomain()
  }
}

export function isAuthed() {
  let token = cookies.get("notaguess_token");
  return (token !== undefined);
}

export function triggerLogout() {
  cookies.remove("notaguess_token", { 
      path: '/', 
      domain: cookieDomain(),
      expires: new Date(0).toUTCString()
  });
  window.location = "/";
}

export function triggerLogin() {
  window.location.replace(authorizeUrl());
}

export function convertDateStringToJsCompliant(dateString) {
    if (dateString) {
      return dateString.replace(/-/g, '/');
    }
    return null;
}
