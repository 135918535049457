import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const NagError = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const errorMessages = {
    SQUARE_AUTH_DENIED: "Square authorization denied by user.",
    // Add more error messages as needed.
  };

  var msg = "An error has occured."
  if (searchParams.get("id") in errorMessages) {
    msg = errorMessages[searchParams.get("id")];
  }

  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        {/* <div className="display-1 text-300 fs-error">500</div> */}
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          { msg }
        </p>
        <hr />
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Back to Not/a/guess
        </Link>
      </Card.Body>
    </Card>
  );
};

export default NagError;
