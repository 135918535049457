import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import FooterStandard from 'components/pages/landing/FooterStandard';

import { isAuthed, triggerLogin, triggerLogout } from 'config';

import squareImg from 'assets/img/logos/square.png';
import cloverImg from 'assets/img/logos/clover.svg';

const NagSelectPOSLogin = () => {
  let authed = isAuthed();
  return (
    <>
      <NavbarStandard showNavBarLinks={true} />
      <Container className='pt-8 pb-8'>
        <Row>

          <Col lg={6} className='d-flex justify-content-center'>
            <Card className='mt-4 mb-4' style={{width: '20rem'}}>
              <Card.Img className='p-5' src={squareImg} variant='top'/>
              <Card.Body className='text-center'>
                <Button color="primary" size="sm" onClick={() => { triggerLogin(); } }>
                  Login with Square
                </Button>
              </Card.Body>
            </Card>
          </Col>
        
          <Col lg={6} className='d-flex justify-content-center'>
            <Card className='mt-4 mb-4' style={{width: '20rem'}}>
              <Card.Img  className='p-5' src={cloverImg} variant='top'/>
              <Card.Body className='text-center'>
                <Button color="primary" size="sm" disabled>
                  Coming soon
                </Button>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default NagSelectPOSLogin;
