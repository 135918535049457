import React, { useContext } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { isAuthed } from 'config';
import gtag from 'ga-gtag'

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);


  let authed = isAuthed();
  const demoAction = () => {
    gtag('event', 'conversion', { 'send_to': 'AW-11314551242/3qahCLvB0dcYEMqzmZMq' });
    window.location.replace("/pricing");
  }
  return (
    <>
      {authed &&
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/dashboard"
          >
            Dashboard
          </Nav.Link>
        </Nav.Item>
      }


      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/about"
        >
          About
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          onClick={demoAction}
        >
          Pricing
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/faq"
        >
          FAQ
        </Nav.Link>
      </Nav.Item>

      {/* <NavbarDropdown title="dashboard">
        {dashboardRoutes.children[0].children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>

      <NavbarDropdown title="app">
        <NavbarDropdownApp items={appRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown title="pages">
        <NavbarDropdownPages items={pagesRoutes.children} />
      </NavbarDropdown>
      <NavbarDropdown title="modules">
        <NavbarDropdownModules items={modulesRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown title="documentation">
        {flatRoutes(documentationRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown> */}
    </>
  );
};

export default NavbarTopDropDownMenus;
