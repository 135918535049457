import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { Link } from 'react-router-dom';
import PricingAltCard from './PricingAltCard';
import { pricingAltData as pricingData } from 'data/pricing';

const PricingAlt = () => {
  return (
    <Container className='mb-5 mt-7'>
      <PageHeader
        preTitle="Get started for free!"
        title="Start saving money and stop wasting product."
        description="Get your personalized AI Inventory Forcast with item by item details to maximize profits and reduce wasted products."
        className="mb-3"
        titleTag="h2"
      >
        <Link className="btn btn-sm btn-link ps-0" to="mailto:info@notaguess.com">
          Have questions? Chat with us
        </Link>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={12} lg={9} xl={10} xxl={8}>
              <Row>
                {
                  pricingData.map(item => (
                    <PricingAltCard key={item.id} pricing={item} />
                  ))
                }
              </Row>
            </Col>
            <Col xs={12} className="text-center">
              <h5 className="mt-5">
                Looking to get started without any risk?
              </h5>
              <p className="fs-1">
                Try the <Link to="/selectpos">free version</Link> of Notaguess!
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PricingAlt;
