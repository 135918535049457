import React, { useState, useEffect } from 'react';
import { Form, Card, Col, Container, Row, FloatingLabel, Button , Spinner} from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { useNavigate } from "react-router-dom";
import { isAuthed, apiServerUrl } from 'config'

const FirstLogin = () => {
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  
  let navigate = useNavigate();
  
  useEffect(() => {
    if (!isAuthed()) {
      navigate("/selectpos");
    } 
  }, []);

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    fetch(apiServerUrl() + '/v0/merchant/first/', {
      credentials: 'include',
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: nameValue,
        email: emailValue
      })
    })
    .then(response => {
      if(response.ok) {
        return navigate("/loading");
      } else {
        throw new Error("Not 2xx response", { cause: response });
      }
    })
    .catch(error => {
      console.error(error)
    }); 
  }

  return (
    
    <Container className='mb-4'>
      <PageHeader
        title="Welcome to Not/a/guess!"
        description="Please take a second to share some information with us so we can serve you better."
        className="mb-4 mt-7"
      />
      <Row className="justify-content-center">
        <Col md={{ span: 4 }}>
          <Card className="mb-5">
            <Card.Body className="text-center">
            <Form onSubmit={handleSubmit}>
              <FloatingLabel controlId="floatingInputGrid" label="Your name">
                <Form.Control required type="text" placeholder="Your name" value={nameValue} onChange={handleNameChange} />
              </FloatingLabel>
              <FloatingLabel className="mt-3" controlId="floatingInputGrid" label="Email address">
                <Form.Control required type="email" placeholder="name@example.com" value={emailValue} onChange={handleEmailChange} />
              </FloatingLabel>
              <Button variant="primary" className="mt-4" type="submit">
                Continue
              </Button>
              </Form>
            </Card.Body>
            
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default FirstLogin;
