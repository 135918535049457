import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
import Testimonial from './Testimonial';
import Cta from './Cta';

const Landing = () => {
  return (
    <>
      {/* <NavbarStandard showNavBarLinks={true} /> */}
      <Hero />
      <Partners />
      <Services />
      <Processes />
      
      {/* <Testimonial /> */}
      <Cta />
      {/* <FooterStandard /> */}
    </>
  );
};

export default Landing;
