import illustration1 from 'assets/img/icons/spot-illustrations/48.png';
import illustration2 from 'assets/img/icons/spot-illustrations/16.png';
import illustration3 from 'assets/img/icons/spot-illustrations/creating.png';
import illustration4 from 'assets/img/icons/spot-illustrations/settings.png';

export default [
  {
    icon: ['fa', 'dollar-sign'],
    iconText: 'Saves money',
    color: 'info',
    title: 'Saves money',
    description: 'No more wasted product at the of the day and no more missed sales opportunities when you run out of product in the middle of the day.',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'Innovative',
    color: 'info',
    title: 'Innovative',
    description:
      "Next day inventory recommendations you can trust produced by our state of the art AI/ML algorithms custom tailored to your business.",
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'Versatile',
    color: 'info',
    title: 'Versatile',
    description:
      'Easy onboarding and integration requires no commitment. Works where you work and seemlessly connects with your Point Of Sale.',
    image: illustration3
  },
  {
    icon: ['fa', 'lock'],
    iconText: 'Private',
    color: 'info',
    title: 'Private',
    description:
      'Your data belongs to you and we will not share it with anyone. Backed by the industry leading Cloud Service Provider.',
    image: illustration4,
    inverse: true
  }
];
