import React from 'react';
import { Table, Card, Col, Container, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const NagDocs = () => {
  return (
    <Container className='mb-4'>
      <PageHeader
        title="Docs"
        description="Under development."
        className="mb-4 mt-7"
      />
      
    </Container>
  );
};

export default NagDocs;
