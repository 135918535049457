export default [
  {
    media: { icon: ['far', 'file'], color: 'warning', className: 'fs-4' },
    title: 'We ingest your data',
    description:
      "Download current and historical order information from your Point of Sale terminal."
  },
  {
    media: { icon: ['far', 'lightbulb'], color: 'info', className: 'fs-5' },
    title: 'State-of-the-art Machine Learning',
    description:
      'Harness the power of AI/ML prediction techniques and leveraging our proprietary blend of historical product performance and industry expertise.'
  },
  {
    media: { icon: ['far', 'star'], color: 'success', className: 'fs-6' },
    title: 'Precise inventory forecasts',
    description:
      'Enjoy newfound revenue and operational efficiency from reducing your waste while maximizing profits.'
  }
];
