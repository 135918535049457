import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import className from 'classnames';
import serviceList from 'data/feature/serviceList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import carouselImg1 from 'assets/img/nag/landing/carousel/1.png';
import carouselImg2 from 'assets/img/nag/landing/carousel/2.png';
import carouselImg3 from 'assets/img/nag/landing/carousel/3.png';

const Services = () => (
  <Section className="text-center">
    <SectionHeader
      title="How does it work?"
      subtitle="Empower your small businesses with cutting-edge AI/ML prediction technology."
    />
    <Row className="mt-6">
      {serviceList.map((service, index) => (
        <Col
          lg={4}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >
          <CardService {...service} />
        </Col>
      ))}
    </Row>
    <Row className='mt-6'>
      <Col className='d-flex justify-content-center'>
        <Carousel variant="dark" className='w-75 theme-slider'
          nextIcon={
            <FontAwesomeIcon icon="angle-right" />
          }
          prevIcon={
            <FontAwesomeIcon icon="angle-left" />
          }>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 rounded"
              src={carouselImg1}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 rounded"
              src={carouselImg2}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 rounded"
              src={carouselImg3}
            />
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  </Section>
);

export default Services;
