import { tooltipFormatter } from 'helpers/echart-utils';
import { getColor, rgbaColor } from 'helpers/utils';

export const getEChartsOptionLineChart = (salesData, seriesTitle) => { 
    if (!salesData) {
      return {};
    }
    const [x_arr, y_arr] = salesData;
    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: getColor('dark'),
        borderWidth: 1,
        formatter: tooltipFormatter,
        transitionDuration: 0,
        axisPointer: {
          type: 'none'
        }
      },
      xAxis: {
        type: 'category',
        data: x_arr,
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: getColor('gray-300')
          }
        },
        axisTick: { show: false },
        axisLabel: {
          color: getColor('gray-400'),
          // formatter: value => value.substring(0, 3),
          margin: 15
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: getColor('gray-200')
          }
        },
        boundaryGap: false,
        axisLabel: {
          show: true,
          color: getColor('gray-400'),
          margin: 15
        },
        axisTick: { show: false },
        axisLine: { show: false }
      },
      series: [
        {
          name: seriesTitle,
          type: 'line',
          data: y_arr,
          itemStyle: {
            color: getColor('white'),
            borderColor: getColor('primary'),
            borderWidth: 2
          },
          lineStyle: {
            color: getColor('primary')
          },
          showSymbol: false,
          symbol: 'circle',
          symbolSize: 10,
          smooth: false,
          emphasis: {
            scale: true
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(getColor('primary'), 0.5)
                },
                {
                  offset: 1,
                  color: rgbaColor(getColor('primary'), 0)
                }
              ]
            }
          }
        }
      ],
      grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
    };
}

export const convertTimestampToDate = (timestampInMilli) => {
  const date = new Date(timestampInMilli); 
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); 
  const day = ('0' + date.getDate()).slice(-2);
  return `${month}/${day}`;
}
