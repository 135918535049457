import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { apiServerUrl } from '../../config'

import NagSimpleStatBox from 'components/notaguess/NagSimpleStatBox';
import { convertTimestampToDate } from 'helpers/nag-echart-utils';

import demoImg1 from 'assets/img/demo/missed_sales.png';

function Forecast({ locationId, itemId, fullItemName, showDetailedForecast, showDetailsLink, showDemoImg }) {
  const [showChart, setShowChart] = useState(false)
  const [errorForecastMessage, setErrorForecastMessage] = useState(null)
  const [errorMissedSalesMessage, setErrorMissedSalesMessage] = useState(null)
  const [chartTitle, setChartTitle] = useState("")
  const [data, setData] = useState(null);
  const [rawForecastData, setRawForecastData] = useState(null);
  const [missedSalesInfo, setMissedSalesInfo] = useState(null);
  const [allForecasts, setAllForecasts] = useState(null);
  const chartOptions = {
    colors: ['#000000', '#FFD4A3'],
    intervals: { style: "area", 'color': '#FFD4A3' }
  };

  useEffect(() => {
    //fetchAllForecasts();
    fetchForecastFromKey(null, "_latest");
    fetchMissedSalesStats();
  }, [locationId, itemId, fullItemName]);


  const convertJsonToGoogleChartsFormat = (jsonArr) => {
    // converts to the expected gcharts format
    let arr = []
    arr.push([
      { type: "string", label: "Date" },
      { type: "number", label: "Actual" },
      { type: "number", label: "Forecast" },
      { id: "i0", type: "number", role: "interval" },
      { id: "i0", type: "number", role: "interval" },
    ])
    jsonArr.forEach(function (item, index) {
      let date = convertTimestampToDate(item['ds']);
      let actual = 'y' in item ? item['y'] : 0;
      let pred = item['yhat']
      let pred_low = item['yhat_lower']
      let pred_high = item['yhat_upper']
      arr.push([
        date,
        actual,
        pred,
        pred_low,
        pred_high]);
    });
    return arr
  }

  const fetchMissedSalesStats = () => {
    fetch(apiServerUrl() + '/v0/merchant/location/' + locationId + '/item/' + itemId + '/missed/_latest/', {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(response => {
        if ("error" in response) {
          setErrorMissedSalesMessage("Missed sales data not yet available");
          return;
        }
        setMissedSalesInfo(response);
        setErrorMissedSalesMessage(null);
      })
      .catch(error => {
        setErrorMissedSalesMessage("Something went wrong");
        console.error(error);
      });
  };


  const fetchForecastFromKey = (e, key) => {
    if (e != null) {
      e.preventDefault();
    }
    // api expects date with dashes
    let forecastDate = key.replaceAll('/', '-');
    fetch(apiServerUrl() + '/v0/merchant/predictions/location/' + locationId + '/item/' + itemId + '/' + forecastDate, {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        if ("error" in data) {
          setErrorForecastMessage("Forecast not yet available");
          return;
        }
        setChartTitle("Forecast for {" + fullItemName + "} generated on " + data.metadata.date)
        setData(convertJsonToGoogleChartsFormat(data.forecast));
        setRawForecastData(data.forecast);
        setShowChart(true);
        setErrorForecastMessage(null);
      })
      .catch(error => {
        setErrorForecastMessage("Something went wrong");
        console.error(error);
      });
  }

  const fetchAllForecasts = () => {
    fetch(apiServerUrl() + '/v0/merchant/predictions/location/' + locationId + '/item/' + itemId, {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        setAllForecasts(data);
      })
      .catch(error => console.error(error));
  };


  return (
    <div>
      {showDetailedForecast &&
        <FalconCardHeader
          title={ "Forecasts> " + fullItemName }
          titleTag="h5"
        />
      }
      {!errorMissedSalesMessage && !errorForecastMessage && showChart && rawForecastData &&
        <>
          {showDemoImg &&
            <div style={{ "padding": "20px" }}>
              <img src={demoImg1} width="100%" />
            </div>
          }

          {showDetailedForecast &&
            <Card className='mb-3'>
              <FalconCardHeader
                light
                title="Last 30 days"
                titleTag="h5"
                className='mb-2'
              />
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={chartOptions} />

            </Card>
          }
          {!showDemoImg &&
            <NagSimpleStatBox
              title={showDetailedForecast ? fullItemName : fullItemName + " last day"}
              forecastData={rawForecastData}
              missedSalesInfo={missedSalesInfo}
              productLink={showDetailsLink ? "/dashboard/product?locationId=" + locationId + "&itemId=" + itemId : null} />
          }
        </>
      }
      { (errorForecastMessage || errorMissedSalesMessage)  &&
          <Card className='m-3 p-5'>
            <p className="text-muted">
              {errorForecastMessage}
            </p>
            <p className="text-muted">
              {errorMissedSalesMessage}
            </p>
          </Card>
      }
    </div>
  );
}


Forecast.propTypes = {
  locationId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  fullItemName: PropTypes.string.isRequired,
  showDetailedForecast: PropTypes.bool.isRequired,
  showDetailsLink: PropTypes.bool.isRequired,
  showDemoImg: PropTypes.bool.isRequired
};

export default Forecast;
