import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Card, Col, Row, Link } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { nagFaq as faqsData } from 'data/faqs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import createMarkup from 'helpers/createMarkup';


const FaqItem = ({ faq, isLast }) => {
  return (
    <>
      <h6 className='text-primary'>

        {faq.title}
        <FontAwesomeIcon icon="caret-right" className="ms-2" />

      </h6>

      <div
        className="ps-3 fs--1 mb-0"
        dangerouslySetInnerHTML={createMarkup(faq.description)}
      />
      {!isLast && <hr className="my-3" />}
    </>
  );
};

const FaqBasicCard = ({ faqs, header, headerText, bodyClass }) => {
  return (
    <Card className="mb-3">
      {header && (
        <Card.Header>
          <h4 className="text-center mb-0">{headerText}</h4>
        </Card.Header>
      )}

      <Card.Body className={classNames(bodyClass)}>
        {faqs.map((faq, index) => (
          <FaqItem
            key={faq.id}
            faq={faq}
            isLast={index === faqs.length - 1}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <Flex alignItems={'center'}>
          <h5 className="d-inline-block me-3 mb-0 fs--1">
            Was this information helpful?
          </h5>
          <Button variant="falcon-default" size="sm">
            Yes
          </Button>
          <Button variant="falcon-default" size="sm" className="ms-2">
            No
          </Button>
        </Flex>
      </Card.Footer>
    </Card>
  );
};

FaqBasicCard.propTypes = {
  faqs: PropTypes.array.isRequired,
  header: PropTypes.bool,
  headerText: PropTypes.string,
  bodyClass: PropTypes.string
};

const NagFaq = () => {
  const [faqs] = useState(faqsData);
  return (
    <Container className='mb-5'>
      <PageHeader
        title="Frequently Asked Questions"
        description="Below you'll find answers to the questions we get asked the most."
        className="mb-4 mt-7"
      />
      <FaqBasicCard faqs={faqs} />
    </Container>
  );
};

FaqItem.propTypes = {
  isLast: PropTypes.bool,
  faq: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};

export default NagFaq;
