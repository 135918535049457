import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Alert, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';


const NagItemsTable = ({columns, locationItems}) => {

    return (
      <AdvanceTableWrapper
        columns={columns}
        data={locationItems}
        sortable
        pagination
        perPage={15}
      >
        <Row className="mb-3">
          <Col xs="auto">
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={locationItems.length}
            table
            rowInfo
            navButtons
            
          />
        </div>
      </AdvanceTableWrapper>
    );
  

}

NagItemsTable.propTypes = {
    columns: PropTypes.array.isRequired,
    locationItems: PropTypes.array.isRequired
};

export default NagItemsTable;