import React, { useState, useEffect, useContext } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Row, Button, Form, Spinner } from 'react-bootstrap';
import { isAuthed, authorizeSquareUrl } from '../../config.js'
import PageHeader from 'components/common/PageHeader';
import { apiServerUrl, triggerLogin } from '../../config'
import NagItemsTable from 'components/notaguess/NagItemsTable';
import { LocationContext, LinkingContext, NotificationContext } from 'context/Context';

function NagSettings() {

  const columns = [
    {
      accessor: 'ShouldTrack',
      Header: 'ShouldTrack',
      Cell: rowData => {
        let shouldTrack = rowData.row.original.ShouldTrack
        return (
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              defaultChecked={shouldTrack}
              onChange={
                e => handleShouldTrackChange(e.target, rowData.row.original)
              }
            />
          </Form>
        )
      }
    },
    {
      accessor: 'ItemName',
      Header: 'ItemName'
    },
    {
      accessor: 'ItemVariationName',
      Header: 'ItemVariationName'
    },
    {
      accessor: 'ItemVariationID',
      Header: 'ItemVariationID'
    },
    {
      accessor: 'Price',
      Header: 'Price'
    }
  ];
  const { selectedLocation, loading } = useContext(LocationContext);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedLocationHydrated, setSelectedLocationHydrated] = useState(null);
  const [tokenStatus, setTokenStatus] = useState({ type: "loading" });
  const { isLinked, linkedLoading, setIsLinked } = useContext(LinkingContext);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!isAuthed()) {
      triggerLogin();
      return;
    }
    if (!isLinked && !linkedLoading) {
      showNotification("Please connect your Square account by going to the Settings page.", "danger");
    }
  }, [isLinked, linkedLoading]);


  useEffect(() => {
    if (isLinked) {
      fetchItemsForLocation();
      handleGetTokenStatus();
    }
  }, [selectedLocation, isLinked]);

  const handleShouldTrackChange = (target, originalRow) => {
    let shouldTrack = target.checked;
    fetch(apiServerUrl() + '/v0/merchant/location/' + originalRow.LocationID + '/item/' + originalRow.ID + '/shouldTrack', {
      'credentials': 'include',
      'method': 'PATCH',
      'body': JSON.stringify({ ShouldTrack: shouldTrack })
    })
      .then(response => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response", { cause: response });
        } else {
          // got the desired response
          return response.json()
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const fetchItemsForLocation = () => {
    setLoadingPage(true);
    if (!selectedLocation) { return }
    fetch(apiServerUrl() + '/v0/merchant/location/' + selectedLocation.ID + '/items/', {
      'credentials': 'include',
    })
      .then(response => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response", { cause: response });
        } else {
          // got the desired response
          return response.json()
        }
      })
      .then(data => {
        let newLocation = {
          "info": selectedLocation,
          "items": data.map(function (object) {
            return { ...object, LocationID: selectedLocation.ID };
          })
        };
        setSelectedLocationHydrated(newLocation.items)
        setLoadingPage(false);
      })
      .catch(error => {
        setLoadingPage(false);
        console.error(error);
      });
  };

  const handleDelete = () => {
    fetch(apiServerUrl() + '/v0/merchant/', {
      'credentials': 'include',
      method: 'DELETE'
    }).then(response => {
      setIsLinked(false);
    })
      .catch(error => {
        console.error(error);
        showNotification("Unfortunately an unexpected error has occured. Please reach out to us at info@notaguess.com to finish the deletion.", "warning");
      });
  };

  const handleSyncCatalog = () => {
    fetch(apiServerUrl() + '/v0/merchant/synccatalog', {
      'credentials': 'include',
      method: 'PATCH'
    })
      .then(response => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response", { cause: response });
        } else {
          // got the desired response
          return response.json()
        }
      })
      .then(data => {
        console.log(data);
        showNotification("Syncing your latest data from Square.", "success");
      })
      .catch(error => {
        console.error(error);
        showNotification("Unfortunately an unexpected error has occured. Please reach out to us at info@notaguess.com to finish the sync.", "warning");
      });
  };

  const handleGetTokenStatus = () => {
    fetch(apiServerUrl() + '/v0/merchant/token/status', {
      'credentials': 'include',
      method: 'GET'
    }).then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        setTokenStatus(data)
      })
      .catch(error => {
        console.error(error);
        setTokenStatus({ "type": "Couldn't fetch" })
      });
  };

  const handleConnectSquare = () => {
    window.location.replace(authorizeSquareUrl());
  };

  const handleDisconnectSquare = () => {
    fetch(apiServerUrl() + '/v0/user/square', {
      'credentials': 'include',
      method: 'DELETE'
    }).then(response => {
      setIsLinked(false)
    })
      .catch(error => {
        console.error(error);
        showNotification("Unfortunately an unexpected error has occured. Please reach out to us at info@notaguess.com to unlink your Square account.", "warning");
      });
  };

  if (!isLinked) {
    return (
      <>
        <PageHeader
          title="Settings"
          description="Control all administrative functions of your account."
          className="mb-4"
        />

        <Card className='mb-4'>
          <FalconCardHeader
            light
            title="Please connect your square account"
            titleTag="h5"
          />
          <Card.Body className="py-2">
            <Card.Text>
              Please connect your Square account to start generating predictions.
            </Card.Text>
            <Button variant="success" size="md" onClick={handleConnectSquare}>
              Connect Square Account
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  // IMPORTANT: this should be after isLinked
  if (loadingPage || loading || linkedLoading) {
    return (
      <Row className="g-3 mb-3">
        <Spinner animation="grow" size="sm" />
      </Row>
    );
  }

  return (
    <>
      <PageHeader
        title="Settings"
        description="Control all administrative functions of your account."
        className="mb-4"
      />
      <Card className='mb-4'>
        <FalconCardHeader
          light
          title="Select items to track"
          titleTag="h5"
        />
        <Card.Body className="py-2">
          <Row className="justify-content-center mb-5">
            <Col>
              <NagItemsTable columns={columns} locationItems={selectedLocationHydrated} />
            </Col>
          </Row>

        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <FalconCardHeader
          light
          title="Square Access Token Status"
          titleTag="h5"
        />
        <Card.Body className="py-2">
          <Card.Text>
            Here you can see information about your Square account access token.
            Information like what permissions Not/a/guess uses and when the access token expires.
            You can always revoke this access token by deleting your account.
          </Card.Text>

          {tokenStatus.type &&
            <div>
              <b>Status:</b> {tokenStatus.type}
            </div>
          }
          {!tokenStatus.type &&
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td><b>Expires at</b></td>
                  <td>{tokenStatus.expires_at}</td>
                </tr>
                <tr>
                  <td><b>MerchantId</b></td>
                  <td>{tokenStatus.merchant_id}</td>
                </tr>
                <tr>
                  <td><b>Scopes</b></td>
                  <td dangerouslySetInnerHTML={tokenStatus.scopes && { __html: tokenStatus.scopes.join('<br/>') }}></td>
                </tr>
              </tbody>
            </table>
          }

        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <FalconCardHeader
          light
          title="Sync Catalog"
          titleTag="h5"
        />
        <Card.Body className="py-2">
          <Card.Text>
            Your locations and catalog items are sync'd daily. If you wan to manually trigger an inventory refresh, use button below.
          </Card.Text>
          <Button variant="primary" size="md" onClick={handleSyncCatalog}>
            Trigger Catalog Sync
          </Button>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <FalconCardHeader
          light
          title="Danger zone"
          titleTag="h5"
        />
        <Card.Body className="py-2 border-bottom pb-4">
          <Card.Text>
            If you would like to disconnect the currently linked Square account you can un-link it here. If you have any further questions or concerns please reach out to us at <a href="mailto:info@notaguess.com">by email</a>.
          </Card.Text>
          <Button variant="danger" size="md" onClick={handleDisconnectSquare}>
            Disconnect square
          </Button>
        </Card.Body>


        <Card.Body className="py-2 pb-4 pt-4">
          <Card.Text>
            If you no longer wish to receive predictions and forecasts for your inventory across all your locations, you can delete your account which will revoke the permissions that were granted to us when you originally signed up. If you have any further questions or concerns please reach out to us at <a href="mailto:info@notaguess.com">by email</a>.
          </Card.Text>
          <Button variant="danger" size="md" onClick={handleDelete}>
            Delete account
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}

export default NagSettings;