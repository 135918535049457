import React, { useState, useEffect, useContext } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Col, Row, Spinner } from 'react-bootstrap';
import { LocationContext } from 'context/Context';
import { useNavigate, useParams } from 'react-router-dom';

import Forecast from 'components/notaguess/Forecast'
import { isAuthed, triggerLogin, apiServerUrl } from 'config'

const NagProduct = () => {

  // Read url params
  const urlParams = new URLSearchParams(window.location.search);
  const locationId = urlParams.get('locationId');
  const itemId = urlParams.get('itemId');
  const navigate = useNavigate();
  const [itemInfo, setItemInfo] = useState(null);
  const [loadingPage, setLoading] = useState(true);
  const { selectedLocation, loading } = useContext(LocationContext);

  useEffect(() => {
    if (!isAuthed()) {
      triggerLogin();
    } else {
      fetchItems();
    }
  }, []);

  useEffect(() => {
    if (selectedLocation.ID != locationId) {
      navigate('/dashboard');
    }
  }, [selectedLocation]);

  const fetchItems = () => {
    setLoading(false);

    fetch(apiServerUrl() + '/v0/merchant/location/' + locationId + '/items/', {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        data.forEach(itemInfo => {
          if (itemInfo.ID == itemId) {
            setItemInfo(itemInfo);
          }
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error)
      });
  };

  if (loadingPage || loading) {
    return (
      <Row className="g-3 mb-3">
        <Spinner animation="grow" size="sm" />
      </Row>
    );
  }

  return (
    <>

      <FalconCardHeader
        title={itemInfo ? "Product " + itemInfo.ItemVariationName + ' ' + itemInfo.ItemName : "Product "}
        titleTag="h5"
        className="py-2"
      />


      <Row className="g-3 mb-3">
        <Col>
          <div id="forecast">
            {itemInfo &&
              <Forecast
                locationId={selectedLocation.ID}
                itemId={itemId}
                fullItemName={itemInfo.ItemVariationName + ' ' + itemInfo.ItemName}
                showDetailedForecast={true}
                showDetailsLink={false}
                showDemoImg={false} />
            }
          </div>
        </Col>
      </Row>

    </>
  );
};

export default NagProduct;

