import React from 'react';
import { Table, Card, Col, Container, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const NagStatus = () => {
  return (
    <Container className='mb-5'>
      <PageHeader
        title="System status"
        description="This page shows an up-to-date service availability across all parts of the Notaguess platform."
        className="mb-4 mt-7"
      />
      <Card>
        <Card.Body>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th scope="col">Service</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Authentication</td>
                <td><FontAwesomeIcon className="text-success" icon={faCheckCircle} /></td>
              </tr>
              <tr>
                <td>Dashboard</td>
                <td><FontAwesomeIcon className='text-success' icon={faCheckCircle} /></td>
              </tr>
              <tr>
                <td>API</td>
                <td><FontAwesomeIcon className='text-success' icon={faCheckCircle} /></td>
              </tr>
              <tr>
                <td>Data processing</td>
                <td><FontAwesomeIcon className='text-success' icon={faCheckCircle} /></td>
              </tr>
              <tr>
                <td>Prediction engine</td>
                <td><FontAwesomeIcon className='text-success' icon={faCheckCircle} /></td>
              </tr>
            </tbody>
          </Table>
          <Flex direction='row-reverse' className='mt-3 mb-0'>  
            <h6>Last updated: {new Date().toUTCString()}</h6>
          </Flex>
          
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NagStatus;
