import bw1 from 'assets/img/logos/square.png';
import bw2 from 'assets/img/logos/clover.svg';
import bw3 from 'assets/img/logos/b&w/2.png';
import bw4 from 'assets/img/logos/b&w/4.png';
import bw5 from 'assets/img/logos/b&w/1.png';
import bw6 from 'assets/img/logos/b&w/10.png';
import bw7 from 'assets/img/logos/b&w/12.png';

export default [
  { src: bw1, height: 40 },
  { src: bw2, height: 40 },
];
