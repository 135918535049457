import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { LocationContext } from 'context/Context';
import { isAuthed, apiServerUrl } from 'config'
import { getUrlParameter } from 'helpers/utils';
import { LinkingContext } from 'context/Context';

const LocationProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isLinked } = useContext(LinkingContext);

  useEffect(() => {
    if (isAuthed() && isLinked) {
      fetchLocations();
    }
  }, [isLinked]);

  useEffect(() => {
    const savedLocationId = localStorage.getItem('selectedLocationId');
    if (savedLocationId) {
      const savedLocation = locations.find(loc => loc.ID === parseInt(savedLocationId, 10));
      if (savedLocation) {
        setSelectedLocation(savedLocation);
      } else {
        // localStorage.removeItem('selectedLocationId');
      }
    }
  }, [locations]);

  const fetchLocations = () => {
    setLoading(true)
    fetch(apiServerUrl() + '/v0/merchant/locations/', {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        if (data.length === 0) {
          console.log("no locations found")
          return
        }
        setLocations(data);
        var locationToUse = data[0];

        // force location if passed as url parameter
        let forceLocationId = getUrlParameter("lId") 
        if (forceLocationId) {
          let foundLocation = data.find(loc => loc.ID == parseInt(forceLocationId, 10))
          if (foundLocation) {
            locationToUse = foundLocation;
          }
        }

        // Set the selected location from localStorage if available
        const savedLocationId = localStorage.getItem('selectedLocationId');
        if (savedLocationId) {
          const savedLocation = data.find(loc => loc.ID === parseInt(savedLocationId, 10));
          if (savedLocation) {
            locationToUse = savedLocation;
          } else {
            // localStorage.removeItem('selectedLocationId');
          }
        }

        setSelectedLocation(locationToUse);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error)
      });
  };

  return (
    <LocationContext.Provider
      value={{
        locations,
        selectedLocation,
        loading,
        setSelectedLocation: (location) => {
          setSelectedLocation(location);
          localStorage.setItem('selectedLocationId', location.ID); // Save to localStorage
        }
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LocationProvider;