import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';
import corner2 from 'assets/img/illustrations/corner-2.png';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle'
import IconAlert from 'components/common/IconAlert';
import Forecast from 'components/notaguess/Forecast'
import NagLocationStats from 'components/notaguess/NagLocationStats'
import { LocationContext, LinkingContext, NotificationContext } from 'context/Context';
import { isAuthed, triggerLogin, apiServerUrl } from 'config'

const NagDashboard = () => {
  const [pageLoading, setLoading] = useState(true);
  const [selectedLocationHydrated, setSelectedLocationHydrated] = useState(null);
  const { selectedLocation, loading } = useContext(LocationContext);
  const { isLinked, linkedLoading } = useContext(LinkingContext);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!isAuthed()) {
      triggerLogin();
      return;
    }
    if (!isLinked && !linkedLoading) {
      showNotification("Please connect your Square account by going to the Settings page.", "danger");
    }
  }, [isLinked, linkedLoading]);


  // selectedLocation is never set because the user is not linkeddsaw
  useEffect(() => {
    if (!isAuthed()) {
      triggerLogin();
      return
    }
    if (isAuthed() && isLinked) {
      fetchStatsForLocation();
    }
  }, [selectedLocation, isLinked]);

  const fetchStatsForLocation = () => {
    if (!selectedLocation) { return }
    fetch(apiServerUrl() + '/v0/merchant/location/' + selectedLocation.ID + '/stats/', {
      'credentials': 'include',
    })
      .then(response => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Not 2xx response", { cause: response });
        } else {
          // got the desired response
          return response.json()
        }
      })
      .then(data => {
        if (!data) { return }
        let topSortedItems = data['item_gross'].sort((a, b) => (a.gross_amount > b.gross_amount) ? -1 : 1).slice(0, 10);
        let bottomSortedItems = data['item_gross'].sort((a, b) => (a.gross_amount < b.gross_amount) ? -1 : 1).slice(0, 10);
        let newLocation = {
          "info": selectedLocation,
          "items": data,
          "totalSales": data['item_gross'].reduce((acc, curr) => acc + curr.gross_amount, 0),
          "latestAggregateDate": data['aggregates'] ? Object.keys(data['aggregates']).sort().reverse()[0] : null,
          "missedSales": data['missed_sales'],
          "bottomSorted": bottomSortedItems.map(item => {
            var v = Math.ceil(Math.random() * 30)
            return {
              "nagItemId": item.nag_item_id,
              "name": item.name,
              "quantity": v, // TODO: actually compute the real value
              "amount": v * Math.ceil(Math.random() * 10) // TODO: actually compute the real value
            }
          }),
          "topSorted": topSortedItems.map(item => {
            return {
              "nagItemId": item.nag_item_id,
              "name": item.name,
              "quantity": item.quantity,
              "amount": item.gross_amount,
              "missed_quantity": item.missed_quantity,
              "missed_amount": item.missed_amount
            }
          })
        }; isLinked
        setSelectedLocationHydrated(newLocation);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
        if (error.cause) {
          console.error(error.cause);
        }
        setSelectedLocationHydrated(null);
      });
  };

  if (isAuthed && isLinked && (pageLoading || loading)) {
    return (
      <Row className="g-3 mb-3">
        <Spinner animation="grow" size="sm" />
      </Row>
    );
  }

  console.log(selectedLocationHydrated);

  if (selectedLocationHydrated && selectedLocationHydrated.latestAggregateDate) {
    // if the latest aggregate date is more than 30 days ago then show an alert and return
    const latestDate = new Date(selectedLocationHydrated.latestAggregateDate);
    const diffTime = new Date() - latestDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays >= 25) {
      // we stop generating new stats.json files in backend pipeline when we have no sales for last 30 days
      return (
        <IconAlert variant={"info"} className="mb-5">
          <p className="mb-0">We couldn't find data for this location for the last 30 days! Please make some sales and come back!</p>
        </IconAlert>
      );
    }
  }

  return (
    <>
      {selectedLocationHydrated && selectedLocationHydrated.items && selectedLocationHydrated.items.first_run === true &&
        <IconAlert variant={"info"} className="mb-5">
          <p className="mb-0">First run AI insights is still pending for this store! Check back in a bit!</p>
        </IconAlert>
      }
      {!selectedLocationHydrated &&
        <IconAlert variant={"info"} className="mb-5">
          <p className="mb-0">We don't have store data yet, please check back in a bit!</p>
        </IconAlert>
      }
      <WidgetSectionTitle
        icon="list"
        title="Inventory efficiency"
        subtitle="Learn where you can increase your sales and decrease waste with detailed action recommendations."
        transform="shrink-2"
        className="mb-4"
      />

      <Row>
        <Col md="6">
          {selectedLocationHydrated && selectedLocationHydrated.missedSales &&
            <Card className='mb-2'>
              <FalconCardHeader
                light
                title={"Total Sales last 30 days"}
                titleTag="h5"
              />
              <Background image={corner1} className="p-x1 bg-card" />
              <Card.Body className="py-1 text-center">
                <Flex direction='row' justifyContent='left' alignItems='center'>
                  <div>
                    <div className="icon-circle icon-circle-warning">
                      <FontAwesomeIcon
                        icon="dollar-sign"
                        className="fs-2 text-warning align-self-center"
                      />
                    </div>
                  </div>
                  <CountUp
                    start={0}
                    end={Math.round(selectedLocationHydrated.totalSales)}
                    duration={2.75}
                    className="text-700 h2 px-2"
                    separator=","
                  />
                </Flex>
              </Card.Body>
            </Card>
          }
        </Col>


        <Col md="6">
          {selectedLocationHydrated && selectedLocationHydrated.missedSales &&
            <Card className='mb-2'>
              <FalconCardHeader
                light
                title={"Missed Sales last " + selectedLocationHydrated.missedSales.days_back + " days"}
                titleTag="h5"
              />
              <Background image={corner2} className="p-x1 bg-card" />
              <Card.Body className="py-1 text-center">
                <Flex direction='row' justifyContent='left' alignItems='center'>
                  <div>
                    <div className="icon-circle icon-circle-warning">
                      <FontAwesomeIcon
                        icon="dollar-sign"
                        className="fs-2 text-warning align-self-center"
                      />
                    </div>
                  </div>
                  <CountUp
                    start={0}
                    end={Math.round(selectedLocationHydrated.missedSales.missed_sales_amount)}
                    duration={2.75}
                    className="text-700 h2 px-2"
                    separator=","
                  />
                </Flex>
              </Card.Body>
            </Card>
          }
        </Col>

        {/* <Col md="4">
          {selectedLocationHydrated && selectedLocationHydrated.missedSales &&
            <Card className='mb-4'>
              <FalconCardHeader
                light
                title={"Wasted Product last " + selectedLocationHydrated.missedSales.days_back + " days"}
                titleTag="h5"
              />
              <Background image={corner4} className="p-x1 bg-card" />
              <Card.Body className="py-1 text-center">
                <Flex direction='row' justifyContent='left' alignItems='center'>
                  <div>
                    <div className="icon-circle icon-circle-warning">
                      <FontAwesomeIcon
                        icon="dollar-sign"
                        className="fs-2 text-warning align-self-center"
                      />
                    </div>
                  </div>
                  <CountUp
                    start={0}
                    end="201" //TODO: actually calculate this value
                    duration={2.75}
                    className="text-700 h2 px-2"
                    separator=","
                  />
                </Flex>
              </Card.Body>
            </Card>
          }
        </Col> */}
      </Row>

      <Row>
        {selectedLocationHydrated &&
          <Col md='12'>
            <Card className='mb-4'>
              <FalconCardHeader
                light
                title="Missed sales (stock more of these)"
                titleTag="h5"
              />

              <div className="pb-2">

                <Table hover responsive size="sm">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">$ sold</th>
                      <th scope="col"># sold</th>
                      <th scope="col">$ missed</th>
                      <th scope="col"># missed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedLocationHydrated.topSorted.filter(item => item.missed_quantity > 0).sort((a, b) => (a.amount > b.amount) ? -1 : 1).map(rowData => (
                      <tr key={rowData.nagItemId}>
                        <td>{rowData.name}</td>
                        <td>{"$" + rowData.amount}</td>
                        <td>{rowData.quantity}</td>
                        <td>{"$" + parseFloat(rowData.missed_amount).toFixed(1)}</td>
                        <td><FontAwesomeIcon className='me-1 text-success' icon={faCaretUp} /> {" " + parseFloat(rowData.missed_quantity).toFixed(1)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

              </div>
            </Card>
          </Col>
        }
        {/* 
        <Col md='6'>
          <Card className='mb-4'>
            <FalconCardHeader
              light
              title="Wasted product (stock less of these)"
              titleTag="h5"
            />
            <div className="pb-2">
              {selectedLocationHydrated && selectedLocationHydrated.bottomSorted &&
                <Table hover responsive size="sm">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">$ wasted</th>
                      <th scope="col">How much less?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedLocationHydrated.bottomSorted.sort((a, b) => (a.amount > b.amount) ? -1 : 1).map(rowData => (
                      <tr key={rowData.nagItemId}>
                        <td>{rowData.name}</td>
                        <td>{"$" + rowData.amount}</td>
                        <td> <FontAwesomeIcon className='me-1 text-danger' icon={faCaretDown} />{" " + rowData.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              }
              {!selectedLocationHydrated &&
                <div className="alert alert-info" role="alert">Nothing yet</div>
              }
            </div>
          </Card>

        </Col> */}
      </Row>

      {/* <Card className='mb-4'>
        <FalconCardHeader
          light
          title="Smart Insights"
          titleTag="h5"
        />
        <ListGroup className="list-group-flush" variant="flush">
          <ListGroupItem className='py-3' variant="success"><FontAwesomeIcon className='me-3' icon={faCheckCircle} /><i>pesto vegan-sandwich</i> is your Best-Seller by far!</ListGroupItem>
          <ListGroupItem className='py-3' variant="info"><FontAwesomeIcon className='me-3' icon={faInfoCircle} />You usually stop selling <i>ham-cheese savory-croissant</i> after 1pm!</ListGroupItem>
          <ListGroupItem className='py-3' variant="warning"><FontAwesomeIcon className='me-3' icon={faInfoCircle} />You are wasting food on <i>crepes</i> on Thursdays!</ListGroupItem>
        </ListGroup>
      </Card> */}

      <WidgetSectionTitle
        icon="percentage"
        title="Best Sellers Forecasts"
        subtitle="Look at the top selling products from yesterday to get more insights into what is driving most revenue as well as any missed revenue opportunities."
        transform="shrink-2"
        className="mb-4 mt-3"
      />

      {selectedLocationHydrated &&

        <div>
          {selectedLocationHydrated.topSorted.map((_, index) => {
            if (index % 2 == 0) {
              let item1 = selectedLocationHydrated.topSorted[index]
              var item2 = null
              if (selectedLocationHydrated.topSorted.length >= index + 1) {
                item2 = selectedLocationHydrated.topSorted[index + 1]
              }
              return (
                <Row key={("key" + selectedLocationHydrated.info.ID + index)}>
                  <Col md='6' className='mb-4'>
                    <Forecast locationId={selectedLocationHydrated.info.ID} itemId={item1.nagItemId} fullItemName={item1.name} showDetailedForecast={false} showDetailsLink={true} showDemoImg={false} />
                  </Col>
                  {item2 &&
                    <Col md='6'>
                      <Forecast locationId={selectedLocationHydrated.info.ID} itemId={item2.nagItemId} fullItemName={item2.name} showDetailedForecast={false} showDetailsLink={true} showDemoImg={false} />
                    </Col>
                  }
                </Row>)
            }
          })}
        </div>
      }

      <WidgetSectionTitle
        icon="file"
        title="Total sales last 30-days"
        subtitle="Use this chart to see the overall trend of all sales during the last 30 days. You are looking for general trends such as sales increasing or decreasing over time across all products."
        transform="shrink-2"
        className="mb-4 mt-3"
      />
      {selectedLocationHydrated &&
        <Card>
          <Card.Body>

            <NagLocationStats locationId={selectedLocationHydrated.info.ID} locationName={selectedLocationHydrated.info.Name} />

          </Card.Body>
        </Card>
      }
    </>
  );
};

export default NagDashboard;
