import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import Placeholder from 'react-bootstrap/Placeholder';
import { useNavigate } from "react-router-dom";
import { LocationContext, LinkingContext, NotificationContext } from 'context/Context';

import { isAuthed, apiServerUrl, triggerLogin } from 'config'

const NagLoadingScreen = () => {
  let navigate = useNavigate();

  const [gotStoresInfo, setGotStoresInfo] = useState(false);
  const [gotItemsOrdersDetected, setGotItemsOrdersDetected] = useState(false);
  const [gotFirstStoreStats, setGotFirstStoreStats] = useState(false);
  const [gotFirstStoreForecasts, setGotFirstStoreForecasts] = useState(false);
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // keep state of all the locations statuses as a dictionary
  let totalLocations = null;
  let locationsStatus = {};

  const { isLinked, linkedLoading } = useContext(LinkingContext);

  const checkLocationsAndStats = async () => {
    try {
      const response = await fetch(apiServerUrl() + '/v0/merchant/locations/', {
        credentials: 'include',
      });
      if (response.ok) {
        const locationsData = await response.json();
        totalLocations = locationsData.length;

        if (locationsData.length > 0) {
          setGotStoresInfo(true);
          // Iterate through the location IDs and check stats for each location
          for (const location of locationsData) {

            // Check if items detected
            const items_response = await fetch(apiServerUrl() + '/v0/merchant/location/' + location.ID + '/items/', {
              credentials: 'include',
            });
            if (items_response.ok) {
              let data_items = await items_response.json();
              if (data_items.length > 0) {
                let hasNonNullLastSoldTime = data_items.some(item => item['LastSold'] != null);
                if (hasNonNullLastSoldTime) {
                  setGotItemsOrdersDetected(true);
                }
              }
            }

            const loc_response = await fetch(apiServerUrl() + '/v0/merchant/location/' + location.ID + '/stats/', {
              credentials: 'include',
            });
            if (loc_response.ok) {
              // we got stat for this store
              locationsStatus[location.ID] = true;

              setGotFirstStoreStats(true);
              let data = await loc_response.json();
              // We are ready when merchant stats have been generated properly (or we retrieved al stats but no store data present)
              if (data['first_run'] === false || totalLocations === Object.keys(locationsStatus).length) {

                if (data['first_run'] === false) {
                  setGotFirstStoreForecasts(true);
                }

                if (localStorage.getItem('selectedLocationId') === null) {
                  localStorage.setItem('selectedLocationId', location.ID);
                }

                navigate('/dashboard');
                return;
              }
            } else {
              let data = await loc_response.json();
              if (data.error === "Not found") {
                // store has no data to display
                navigate('/dashboard');
                return;
              }
            }
          }
          setInitialCheckDone(true);
        }
      }
    } catch (error) {
      console.error('Error checking locations:', error);
    }

    setTimeout(checkLocationsAndStats, 20000);
  };

  useEffect(() => {

    if (!isAuthed()) {
      triggerLogin();
      return;
    }
    if (!isLinked && !linkedLoading) {
      navigate("/dashboard");
    } else {
      checkLocationsAndStats();
    }
  }, [isLinked, linkedLoading]);

  const emptyContainerStyle = {
    height: `${window.innerHeight}px`, // Set the height to the screen height
  };

  return (
    <>
      {initialCheckDone ? (
        <Container className='mb-4'>
          <PageHeader
            title="We are preparing your dashboard!"
            className="mb-4 mt-7"
          />
          <Card className='mb-4'>
            <Card.Body className="overflow-hidden">
              <Card style={{ width: '100%' }}>
                <Card.Body>
                  <h4 className='mb-3'>Status</h4>
                  <div>
                    {!gotStoresInfo ? (
                      <Button variant="primary" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        Retrieving store information
                      </Button>
                    ) : (
                      <Button variant="success" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        Retrieved store information
                      </Button>
                    )
                    }
                  </div>
                  <div>
                    {!gotItemsOrdersDetected ? (
                      <Button variant="primary" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        No items/orders detected
                      </Button>
                    ) : (
                      <Button variant="success" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        Items/orders detected
                      </Button>
                    )
                    }
                  </div>
                  <div>
                    {!gotFirstStoreStats ? (
                      <Button variant="primary" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        Calculating store's sales statistics
                      </Button>
                    ) : (
                      <Button variant="success" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        Calculated store's sales statistics
                      </Button>
                    )
                    }
                  </div>
                  <div>
                    {!gotFirstStoreForecasts ? (
                      <Button variant="primary" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        Generating AI insights for your products
                      </Button>
                    ) : (
                      <Button variant="success" className="d-inline-flex flex-center gap-1 mb-1" disabled>
                        Generated AI insights for your products
                      </Button>
                    )
                    }
                  </div>
                </Card.Body>
              </Card>

              <Card style={{ width: '100%' }} className='mt-4'>
                <Card.Body>
                  <p>Please wait while we prepare your first run experience!</p>
                  <p>
                    This shouldn't take more than 5-10 minutes!<br />
                    You'll be redirected to your dashboard as soon as we have the first AI insights for your products!
                  </p>
                  <p>If this takes longer, please <a href='mailto:info@notaguess.com'>let us know</a>.</p>
                </Card.Body>
              </Card>

            </Card.Body>
          </Card>
        </Container>) : (
        <Container className='mb-4' style={emptyContainerStyle}>

        </Container>
      )
      }
    </>
  );
};

export default NagLoadingScreen;
