import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import NavbarStandard from '../components/pages/landing/NavbarStandard';
import FooterStandard from '../components/pages/landing/FooterStandard';
import LinkingProvider from 'components/notaguess/LinkingProvider';
import PropTypes from 'prop-types';


const LandingLayout = ({showNavbarLinks}) => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <LinkingProvider>
      <NavbarStandard showNavbarLinks={ showNavbarLinks }/>      
      {/*------ Main Routes ------*/}
      <Outlet />
      <FooterStandard />
    </LinkingProvider>
  );
};

LandingLayout.propTypes = {
  showNavbarLinks: PropTypes.bool.isRequired
};

export default LandingLayout;

