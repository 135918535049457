import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconAlert from 'components/common/IconAlert';

const NagNotification = ({ message, level, onClose }) => {
    const alertRef = useRef(null);

    useEffect(() => {
        // Scrolls to the alert when component mounts
        alertRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []); // Empty dependency array ensures this effect runs only once on mount
    
    return (
        <IconAlert dismissible={true} variant={level} onClose={onClose} className="mb-3">
            <p ref={alertRef} className="mb-0">{message}</p>
        </IconAlert>
    );
}

NagNotification.propTypes = {
    message: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default NagNotification;