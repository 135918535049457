import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Row, Col, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import FalconCardHeader from 'components/common/FalconCardHeader';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';


import { convertTimestampToDate } from 'helpers/nag-echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (labels, data, color) => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: labels
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor(color), 0.99)
            },
            {
              offset: 1,
              color: rgbaColor(getColor(color), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '0px',
    left: '10px'
  }
});

const NagSimpleStatBox = ({ title, forecastData, missedSalesInfo, productLink }) => {
  // Missed sales calculations for the demo
  var yesterdaySalesColor = "green";
  var yesterdaySalesMissedText = null;
  var yesterdayLastSaleText = null;

  var missedSalesQuantity = null;
  var missedSalesAmount = null;
  var salesChartData = null;
  var salesYesterdayQuantity = null;
  var salesYesterdayAmount = null;

  var salesTomorrowAmount = null;
  var salesTomorrowQuantity = null;
  var salesTomorrowPredictionDate = null;

  if (missedSalesInfo && missedSalesInfo.sales_per_hour) {
    // last days sales data for chart
    let salesLabels = [];
    let salesQuantity = [];
    let salesAmount = [];
    missedSalesInfo.sales_per_hour.forEach(function (item, index) {
      salesLabels.push(item['hour']);
      salesQuantity.push(item['quantity']);
      salesAmount.push(item['amount']);
    });
    salesChartData = [salesLabels, salesQuantity];
    salesYesterdayQuantity = Math.round(salesQuantity.reduce((partialSum, a) => partialSum + a, 0))
    salesYesterdayAmount = Math.round(salesAmount.reduce((partialSum, a) => partialSum + a, 0)) / 100

    if (missedSalesInfo.missed_sales_quantity > 5) {
      yesterdaySalesMissedText = "Sales Missed Yesterday";
      yesterdayLastSaleText = "Last sale " + missedSalesInfo.last_sale_hour;
      missedSalesQuantity = Math.round(missedSalesInfo.missed_sales_quantity);
      if (missedSalesInfo.missed_sales_amount) {
        missedSalesAmount = Math.round(missedSalesInfo.missed_sales_amount * 100) / 100;
      }
      yesterdaySalesColor = "red";
    }
  }

  if (forecastData) {
    let salesTomorrowPrediction = forecastData.find(entry => {
      return entry['yhat'] != null
    })
    if (salesTomorrowPrediction) {
      salesTomorrowQuantity = Math.round(salesTomorrowPrediction['yhat'] * 100) / 100;
      salesTomorrowPredictionDate = convertTimestampToDate(salesTomorrowPrediction['ds']);
      salesTomorrowAmount = Math.round(salesTomorrowQuantity * (salesYesterdayAmount / salesYesterdayQuantity) * 100) / 100
    }
  }

  return (
    <Card className="h-100">
      <FalconCardHeader
        light
        title={title}
        titleTag="h5"
      />
      <Card.Body className="pt-0 pb-3 h-100">
        <div className="mx-ncard">
          <Table borderless className="font-sans-serif fw-medium fs--1">
            <tbody>
              <tr key="sold_yesterday">
                <td className="pb-2 pt-0">Sold yesterday</td>
                <td className="pb-2 pt-0 text-end" style={{ width: '25%' }}>
                  {salesYesterdayQuantity + " pcs"}
                </td>
                <td
                  className="pb-2 pt-0 text-end text-700"
                  style={{ maxWidth: '25%' }}>
                  {"$" + salesYesterdayAmount}
                </td>
              </tr>
              <tr key="missed_yesterday">
                <td className="pb-2 pt-0">Missed yesterday</td>
                <td className="pb-2 pt-0 text-end" style={{ width: '25%' }}>
                  {missedSalesQuantity ? missedSalesQuantity + " pcs" : "N/A"}
                </td>
                <td
                  className="pb-2 pt-0 text-end text-700"
                  style={{ maxWidth: '25%' }}>
                  {missedSalesAmount ? "$" + missedSalesAmount : "N/A"}
                </td>
              </tr>
              <tr key="prediction_tomorrow">
                <td className="pb-2 pt-0">Expected tomorrow</td>
                <td className="pb-2 pt-0 text-end" style={{ width: '25%' }}>
                  {salesTomorrowQuantity + " pcs"}
                </td>
                <td
                  className="pb-2 pt-0 text-end text-700"
                  style={{ maxWidth: '25%' }}>
                  {"$" + salesTomorrowAmount}
                </td>
              </tr>
            </tbody>
          </Table>
          {salesChartData &&
            <Row className="px-4">
              <Col>
                <Card.Text className="fw-medium fs--1 fw-bold">Daily sales by hour</Card.Text>
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(salesChartData[0], salesChartData[1], yesterdaySalesColor)}
                  style={{ width: '100%', height: 80 }}
                />
              </Col>
            </Row>
          }
        </div>
      </Card.Body>
      {productLink && <Card.Footer as={Flex} justifyContent="end" className="border-top py-2 bg-light">
        <Flex>
          <Row>
            <Col xs="auto">
              <Button variant="falcon-default" size="sm" as={Link} to={productLink}>
                More details
              </Button>
            </Col>
          </Row>

        </Flex>
      </Card.Footer>
      }
    </Card>
  );
};

NagSimpleStatBox.propTypes = {
  title: PropTypes.string.isRequired,
  forecastData: PropTypes.array.isRequired,
  missedSalesInfo: PropTypes.object,
  productLink: PropTypes.string
};

export default NagSimpleStatBox;
