import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PricingAltCard from 'components/pages/pricing/pricing-alt/PricingAltCard';
import { pricingAltData as pricingData } from 'data/pricing';
import bg1 from 'assets/img/generic/bg-1.jpg';
import Section from 'components/common/Section';
import gtag from 'ga-gtag'


const Cta = () => { 

  return (
    <Section overlay image={bg1} position="center top" className="light bg-dark">
      <Row className="justify-content-center text-center mb-3">
        <Col lg={8}>
          <p className="fs-3 fs-sm-4 text-white">
          Start making data-driven decisions, reducing product waste and maximizing your profits today!
          </p>
        </Col>
      </Row>
      <Row className="flex-center">
          <Row className="justify-content-center">
            <Col xs={12} lg={9} xl={10} xxl={8}>
              <Row>
                {
                  pricingData.map(item => (
                    <PricingAltCard key={item.id} pricing={item} />
                  ))
                }
              </Row>
            </Col>
          </Row>
        
      </Row>
    </Section>
  );
}

export default Cta;
