import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const nagFaq = [
  {
    id: 0,
    title: `What is Notaguess?`,
    description: `
    <p>We are passionate about providing small businesses with cutting-edge inventory prediction software and solutions. Our headquarters are located in beautiful Seattle, where our mission is to empower small businesses to optimize their inventory management processes, reduce waste, and maximize sales by producing just the right amount of products they need.</p>

    <p>Our Key Offerings:</p>
    <ol>
        <li><strong>Inventory Prediction Software:</strong> We have developed state-of-the-art AI/ML-based inventory prediction software. Our software harnesses advanced machine learning algorithms, analyzing historical product performance data and our own company-specific insights. This enables us to generate highly accurate predictions of inventory requirements for each day.</li>
        <li><strong>Integration with POS Terminals:</strong> Our software seamlessly integrates with leading Point of Sale (POS) terminals and inventory management software used by small businesses. This integration ensures minimal effort is required to implement our solution, making it accessible to a wide range of businesses.</li>
        <li><strong>Real-time Predictions:</strong> Our software provides real-time predictions for inventory levels needed for the day. These predictions are delivered directly to you through email directly to your inbox or our Notaguess App, which can be accessed on your POS terminals, smartphones, or desktop computers. This ensures that you always have the right amount of products on hand to meet customer demand while avoiding overproduction and waste.</li>
        <li><strong>Revenue Enhancement:</strong> By utilizing our software, small businesses can maximize their sales by preventing product shortages and reducing waste. This efficiency leads to increased revenue as businesses can meet customer demands effectively.</li>
        <li><strong>Customer and Community Focus:</strong> We are deeply committed to helping local businesses run more efficiently, better serve their customers, and contribute positively to their communities. Our solutions aim to address common issues like running out of popular products while minimizing the need to discard unsold items.</li>
        <li><strong>Easy Adoption:</strong> Small businesses across the country can easily start using our solutions by installing the Square Notaguess App from the Square marketplace, simplifying the onboarding process.</li>
    </ol>

    <p>In summary, we specialize in providing AI-driven inventory prediction software that empowers small businesses to operate more efficiently, reduce waste, and increase sales by ensuring they always have the right amount of product on hand. Our solutions are designed to be user-friendly and accessible, benefiting both businesses and their local communities.</p>
    `
  },
  {
    id: 1,
    title: 'How does Notaguess work?',
    description: `
    We have developed state-of-the-art AI/ML-based inventory prediction software. Our software harnesses advanced machine learning algorithms, analyzing historical product performance data and our own company-specific insights. This enables us to generate highly accurate predictions of inventory requirements for each day.
    `
  },
  {
    id: 2,
    title: 'What sets you apart from everyone else?',
    description: `
    <p>What sets us apart from everyone else in the field is our unwavering commitment to empowering small businesses with tailored, cutting-edge solutions for inventory management. Several key factors distinguish us:</p>

    <ol>
        <li><strong>Precision and Accuracy:</strong> Our state-of-the-art AI/ML prediction software offers an unprecedented level of precision. By analyzing historical product performance data and applying our company's unique insights, we achieve remarkably accurate predictions, ensuring businesses always have the right amount of inventory.</li>
        <li><strong>Seamless Integration:</strong> We understand the challenges faced by small businesses in adopting new technologies. Notaguess App seamlessly integrates with leading POS terminals and inventory management software, making the transition effortless and reducing implementation barriers.</li>
        <li><strong>Real-time Updates:</strong> Unlike many other solutions, we provide real-time predictions. Our customers receive up-to-the-minute inventory forecasts, enabling them to adapt quickly to changing demands, avoid stockouts, and minimize waste.</li>
        <li><strong>User-Centric Design:</strong> Our user-friendly Notaguess App is accessible on a variety of devices, including POS terminals, smartphones, and desktop computers. It's designed with the end-user in mind, ensuring ease of use and accessibility.</li>
        <li><strong>Revenue Enhancement:</strong> We are not just about efficiency; we are about driving revenue. Our solutions help businesses maximize sales by ensuring that popular products are always available to customers, ultimately increasing profitability.</li>
        <li><strong>Local Business Advocacy:</strong> We are deeply rooted in our commitment to supporting local businesses. Our goal is to enhance their efficiency, enabling them to better serve their customers and contribute positively to their communities.</li>
        <li><strong>Quick Start:</strong> Getting started with Notaguess is hassle-free. Small businesses can begin using our solutions by simply installing the Square Notaguess App from the Square marketplace, making the onboarding process swift and straightforward.</li>
        <li><strong>Continuous Improvement:</strong> We are dedicated to ongoing innovation and improvement. We actively seek and welcome feedback from our users, allowing us to refine our software and provide an even better experience.</li>
    </ol>

    <p>In essence, what sets us apart is our focus on empowering small businesses to thrive through efficient, data-driven inventory management. Our combination of precision, accessibility, and a genuine commitment to our users' success distinguishes us as a trusted partner for businesses looking to optimize their operations and boost their bottom line.</p>
    `
  },
  {
    id: 3,
    title: `Is this only for coffee shops?`,
    description: `
    No. We work with any businesses that sell fresh goods such as Coffee Shops, Bakeries, Restaurants, etc. 
    `
  },
  {
    id: 4,
    title: `Can I provide feedback or suggest new features?`,
    description: `
    We love feedback, please do not hesitate to reach us by <a href="mailto:info@notaguess.com">email</a>, we look forward to hear from you!
    `
  },
  {
    id: 5,
    title: `How do I create an account?`,
    description: `
    We implement OAuth 2.0 authenticaion through the Point Of Sale (POS) platforms that we support (at the moment we support Square). When you login to Notaguess Application, we will redirect you to the Authorization Server of your POS provider and you will login there (we do not see your username or password). Once you successfully login, we receive an "access_token" tailored for our application with the ability to only access the data that you approve. No new account creation is required.
    `
  },
  {
    id: 6,
    title: `How do I reset my password?`,
    description: `
    We implement OAuth 2.0 authenticaion through the Point Of Sale (POS) platforms that we support (at the moment we support Square). We do not store or see your password, you would need to change your password with the Point of Sale provider that you originally used to sign in to Notaguess.
    `
  },
  {
    id: 7,
    title: `How much does the service cost?`,
    description: `
    We are in the Pilot phase of the platform and accepting new customers on individual basis at not cost. We will update our pricing page once the service comes out of the Pilot testing phase.
    `
  },
  {
    id: 8,
    title: `How do you know what recommendations to make?`,
    description: `
    <p>We leverage state-of-the-art inventory prediction software powered by AI/ML (Artificial Intelligence and Machine Learning) techniques. Here's a detailed explanation of how we achieve this:</p>

    <ol>
        <li><strong>Data Collection:</strong> We starts by collecting extensive data related to a business's inventory management. This data typically includes historical sales data, product performance metrics, seasonal variations, customer behavior, and other relevant information.</li>
        <li><strong>Machine Learning Algorithms:</strong> The heart of our solution lies in advanced Machine Learning algorithms. These algorithms process the collected data to identify patterns, trends, and correlations. They can learn how various factors, such as time of day, day of the week, weather, and special events, impact product sales.</li>
        <li><strong>Data Analysis:</strong> These algorithms continuously analyze the data to identify hidden insights that might not be apparent through traditional methods. This analysis helps in understanding which products are in high demand, when they are in demand, and under what circumstances.</li>
        <li><strong>Predictive Modeling:</strong> We use predictive modeling to forecast future sales and inventory requirements. By feeding historical data and real-time inputs into the model, we can generate accurate predictions of how much of each product a business should stock for a given day or period.</li>
        <li><strong>Real-time Updates:</strong> Unlike static forecasts, our system provides real-time updates. This means that as new data becomes available (e.g., the current day's sales), the predictions are adjusted accordingly. This dynamic approach ensures that businesses always have up-to-date insights.</li>
        <li><strong>Alerts and Notifications:</strong> To prevent waste and ensure efficient inventory management, the system can send alerts and notifications to businesses. For example, it can alert a bakery to bake more fresh bread when sales are higher than predicted, or it can warn a café to slow down on coffee brewing if it anticipates a drop in demand.</li>
        <li><strong>Customization:</strong> We often customizes our solution to suit the specific needs of individual businesses. We take into account factors such as product variety, location, customer preferences, and other unique aspects that can affect sales and waste.</li>
        <li><strong>Feedback Loop:</strong> The system can learn from its predictions and adjust over time. If it consistently predicts excess inventory or stockouts for specific products, it can refine its algorithms to better suit the business's needs.</li>
    </ol>

    <p>In summary, we use advanced AI/ML-powered inventory prediction techniques to analyze data, make accurate sales predictions, and dynamically adjust inventory levels in real time. This not only prevents waste but also maximizes sales by ensuring that businesses always have the right amount of products to meet customer demand. It's a data-driven approach that minimizes guesswork and optimizes inventory management.</p>
    `
  },
  {
    id: 9,
    title: `How do I contact technical support?`,
    description: `
    Best way to reach us is by <a href="mailto:info@notaguess.com">email</a>. 
    `
  },
  {
    id: 10,
    title: `How is my personal information protected?`,
    description: `
    <p>Protecting customers' personal information is a critical aspect of our business. Here's a summary of our data protection practices:</p>

    <ol>
        <li><strong>Data Encryption:</strong> We employ encryption techniques to secure customer data during transmission and storage. This ensures that sensitive information, such as names, addresses, and order details, is unreadable to unauthorized parties.</li>
        <li><strong>Access Control:</strong> Access to customer data is restricted to authorized personnel only. We implement robust access control mechanisms, including user authentication and role-based access, to limit who can access, view, or modify customer information.</li>
        <li><strong>Secure Hosting and Storage:</strong> Customer data is stored on secure servers hosted by industry leading Cloud Service Provider.
        <li><strong>Privacy Policies:</strong> We have a clear and comprehensive privacy policy that outlines what data is collected, how it is used, and how it is protected.</li>
        <li><strong>Customer Consent:</strong> Customers are asked for their consent before any data is collected.</li>
    </ol>

    <p>It's important to note that data security is an ongoing process, and we continually assess and update our practices to address evolving threats.</p>
    `
  },
  {
    id: 11,
    title: `What data do you collect and why?`,
    description: `
    <p>We collect the minimum amount of information required to accurately make predictions about your future sales and inventory needs. Here is a list of permissions we ask for:</p>

    <ol>
      <li>READ_ONLY catalog</li>  
      <li>READ_ONLY inventory</li>
      <li>READ_ONLY orders</li>
      <li>READ_ONLY profile information</li>
    </ol>
    `
  },
  {
    id: 12,
    title: `What are your terms of service?`,
    description: `
    Our <a href="/terms">terms of service</a>.
    `
  },
  {
    id: 13,
    title: `What is your privacy policy?`,
    description: `
    Our <a href="/privacy">privacy policy</a>.
    `
  },
  {
    id: 14,
    title: `How do I delete my account?`,
    description: `
    In your Dashboard, under the Settings tab, there is an option to "Delete Account".
    `
  }
];

export const faqs = [
  {
    id: 0,
    title: `How does Falcon's pricing work?`,
    description: `The free version of Falcon is available for teams of up to 15 people. Our Falcon Premium plans of 15 or fewer qualify for a small team discount. As your team grows to 20 users or more and gets more value out of Falcon, you'll get closer to our standard monthly price per seat. The price of a paid Falcon plan is tiered, starting in groups of 5 and 10 users, based on the number of people you have in your Team or Organization.`
  },
  {
    id: 1,
    title: 'What forms of payment do you accept?',
    description: `You can purchase Falcon with any major credit card. For annual subscriptions, we can issue an invoice payable by bank transfer or check. Please contact us to arrange an invoice purchase. Monthly purchases must be paid for by credit card.`
  },
  {
    id: 2,
    title: 'We need to add more people to our team. How will that be billed?',
    description: `You can add as many new teammates as you need before changing your subscription. We will subsequently ask you to correct your subscription to cover current usage.`
  },
  {
    id: 3,
    title: `How secure is Falcon?`,
    description: `Protecting the data you trust to Falcon is our first priority. Falcon uses physical, procedural, and technical safeguards to preserve the integrity and security of your information. We regularly back up your data to prevent data loss and aid in recovery. Additionally, we host data in secure SSAE 16 / SOC1 certified data centers, implement firewalls and access restrictions on our servers to better protect your information, and work with third party security researchers to ensure our practices are secure.`
  },
  {
    id: 4,
    title: `Will I be charged sales tax?`,
    description: `As of May 2016, state and local sales tax will be applied to fees charged to customers with a billing address in the State of New York.`
  },
  {
    id: 5,
    title: `Do you offer discounts?`,
    description: `We've built in discounts at each tier for teams smaller than 15 members. We also offer two months for free in exchange for an annual subscription.`
  },
  {
    id: 6,
    title: `Do you offer academic pricing?`,
    description: `We're happy to work with student groups using Falcon. Contact Us`
  },
  {
    id: 7,
    title: `Is there an on-premise version of Falcon?`,
    description: `We are passionate about the web. We don't plan to offer an internally hosted version of Falcon. We hope you trust us to provide a robust and secure service so you can do the work only your team can do.`
  },
  {
    id: 8,
    title: `What is your refund policy?`,
    description: `We do not offer refunds apart from exceptions listed below. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features. Exceptions to our refund policy: canceling within 48 hours of initial charge will result in a full refund. If you cancel within this timeframe, you will lose access to paid features and associated data immediately upon canceling.`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How long do payouts take?',
    description:
      '<p class = "mb-0">Once you’re set up, payouts arrive in your bank account on a 2-day rolling basis. Or you can opt to receive payouts weekly or monthly.</p>'
  },
  {
    id: 1,
    title: 'How do refunds work?',
    description:
      '<p class = "mb-0">You can issue either partial or full refunds. There are no fees to refund a charge, but the fees from the original charge are not returned.</p>'
  },
  {
    id: 2,
    title: 'How much do disputes costs?',
    description:
      '<p class = "mb-0">Disputed payments (also known as chargebacks) incur a $15.00 fee. If the customer’s bank resolves the dispute in your favor, the fee is fully refunded.</p>'
  },
  {
    id: 3,
    title: 'Is there a fee to use Apple Pay or Google Pay?',
    description:
      '<p class = "mb-0">There are no additional fees for using our mobile SDKs or to accept payments using consumer wallets like Apple Pay or Google Pay.</p>'
  }
];

export const faqDoc = [
  {
    id: 1,
    title: 'How to start working with Falcon React',
    description: `<p class='mb-0'>Install latest version of Node js to your PC <a href='https://nodejs.dev/' target="_blank">https://nodejs.dev/</a> Download the template file and unzip it.
    You will get 2 files in the folder. Now unzip the <code>falcon-react-${version}</code> directory and open this directory from your terminal.
    Run <code>npm install </code> from your terminal.
    After installing all the required packages, run <code>npm start</code>. <br>
    Please check the following link to see How to Getting started in Falcon React: <a target='_blank' href='//${homepage}/documentation/getting-started'>${homepage}/documentation/getting-started<a/>.</p>`
  },
  {
    id: 2,
    title:
      'Is there a specific version of Node.js I should use for Falcon React?',
    description: `<p class="mb-0">Node version to <code>16.15.1</code> or any further updated version.</p>`
  },

  {
    id: 3,
    title: 'What React library is used for general API integration?',
    description: `<p class="mb-0">We used react context API for general API integration.</p>`
  },
  {
    id: 4,
    title: 'How are the SEO headers built?',
    description: `<p class="mb-0">We didn't build with any SEO headers by default. You have to integrate it manually by yourself.</p>`
  },
  {
    id: 5,
    title:
      'Which bootstrap react framework you are using? Reactstrap or React-Bootstrap?',
    description: `<p class="mb-0">We used the <b>reactstrap</b> for Falcon React <code>v2.x</code> and <b>React bootstrap</b> for Falcon React <code>v3.x</code>.</p>`
  },
  {
    id: 6,
    title: 'Where will I get the Figma Files?',
    description: `<p >You will find the Figma link in the <code>README.md</code> file on your downloaded copy of Falcon. Here is the detailed documentation: <a target='_blank' href='//${homepage}/documentation/design-file'> ${homepage}/documentation/design-file</a>.</p>
    <p class='mb-0'>If you still have any problem with the link, reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a></p>`
  },
  {
    id: 7,
    title:
      'What are the bare minimum files just to get the layout and navigation?',
    description: `<p >You can start from the Falcon’s Starter page where the minimum required components are included to get layout and navigation.
    More specifically, the below list of files are required to work with layout and navigation:<br>
    <code>src/assets</code><br>
    <code>src/components/common</code><br>
    <code>src/components/footer</code><br>
    <code>src/components/navbar</code><br>
    <code>src/components/notification</code><br>
    <code>src/components/pages/Starter.js</code><br>
    <code>src/components/settings-panel</code><br>
    <code>src/context</code><br>
    <code>src/data</code><br>
    <code>src/helpers</code><br>
    <code>src/hooks</code><br>
    <code>src/layouts/Layout.js</code><br>
    <code>src/layouts/MainLayout.js</code><br>
    <code>src/layouts/MainRoutes.js</code><br>
    <code>src/reducers</code><br>
    <code>src/routes</code><br>
    <code>src/App.js</code><br>
    <code>src/Config.js</code><br>
    <code>src/Index.js</code><br>
    <code>src/Main.js</code></p>

    <p>Note:  Remember deleting other files/directories will be the cause of errors. You have to remove the corresponding import statement and unnecessary lines from your code manually.
    </p>
    <p class="mb-0">We have created a mini version of Falcon React with all the essential files only. If you are interested, please reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a>.
    </p>`
  },
  {
    id: 8,
    title: 'How to compile SCSS files with Falcon React?',
    description: `<p class="mb-0">To compile scss files in Falcon react run npm run <code>build:css</code> from your terminal, or you can watch the scss files by running npm run <code>watch:css</code> to a separate terminal. 
    Check the link below to have a look on the styling documentation in Falcon React <a target='_blank' href='//${homepage}/documentation/styling'> ${homepage}/documentation/styling</a>
    </p>`
  },
  {
    id: 9,
    title: 'How to update from Falcon React v2.x to the upper version?',
    description: `<p><b>Updating from any earlier version of 3.x to ${version}:</b></br>
    Check out the following link and follow the changes: <a target='_blank' href='//${homepage}/changelog'> ${homepage}/changelog</a>
    </p>
    <p class='mb-0'><b>Updating from version 2 to version 3:</b></br>
    As the latest versions of templates and all their packages have different structures behind their components, you have to update all the components manually into your project.<br>
    So, it would be better to start building applications with the Bootstrap 5 version of Falcon from scratch. You can include any components from your existing project to your current project. Bring the component and its required SCSS and place it into your current project.
    </p>`
  }
];
