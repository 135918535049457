import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {NotificationContext} from 'context/Context';
import NagNotification from './NagNotification';

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);
  
    const showNotification = (message, level) => {
      setNotification({ message, level });
    };
  
    const hideNotification = () => {
      setNotification(null);
    };
  
    return (
      <NotificationContext.Provider value={{ showNotification, hideNotification }}>
        {notification && <NagNotification message={notification.message} level={notification.level} onClose={hideNotification} />}
        {children}
      </NotificationContext.Provider>
    );
  };

  NotificationProvider.propTypes = {
    children: PropTypes.node
  };