import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';

const NagAbout = () => {
  return (
    <Container className='mb-5'>
      <PageHeader
        title="About us"
        className="mb-4 mt-7"
      />
      <Card>
        <Card.Body className="overflow-hidden">
          <h2>Our Mission</h2>

          <p>At Notaguess, our mission is to empower small businesses with cutting-edge AI/ML prediction technology, revolutionizing inventory management and fostering sustainability. Our state-of-the-art inventory prediction software allows small businesses to flourish by minimizing waste and maximizing sales through precise production planning. </p>

          <p>Historically, the potential for technological advancement in small businesses has been hindered by high entry costs. However, our innovative Notaguess App seamlessly integrates with leading POS terminals and inventory management systems, democratizing access to AI-driven insights. By harnessing the power of AI/ML prediction techniques and leveraging our proprietary blend of historical product performance and industry expertise, we offer an unparalleled level of predictive accuracy.</p>

          <p>Through our user-friendly Notaguess Application, accessible on both POS terminals and mobile/desktop devices, businesses receive real-time predictions that optimize inventory levels. No longer will popular bakeries and coffee shops experience product shortages or resort to selling stale items at the end of the day. Our goal is to elevate local businesses' efficiency, enhancing customer service and benefiting communities at large.</p>

          <p>Notaguess operates by continuously gathering inventory data and applying state-of-the-art Machine Learning algorithms, resulting in precise inventory forecasts. As testimonials echo newfound revenue and operational efficiency, we take pride in being an integral partner in our clients' success stories.</p>

          <p>With a commitment to sustainability and profitability and small businesses nationwide are seamlessly embarking on this transformative journey with us.</p>

          <p>At Notaguess, we envision a future where small businesses thrive through data-driven decisions, reduce waste, and foster economic growth. Our mission is to be the catalyst that empowers these businesses to embrace innovation and achieve unprecedented heights in inventory management excellence."</p>

          <h2 className='pt-3'>Our Team</h2>
          <p>At Notaguess, we're passionate about helping small businesses thrive. We know that running a small business can be tough, and we believe that technology can help simplify things and make life easier. </p>
          <p>Our team is made up of industry veterans in the fields for Software Engineering, Data Science (AI/ML), and business experts who understand the challenges of running a small business. We've used our expertise to create a solution that is easy to use, affordable, and effective. With Notaguess, you can say goodbye to guesswork and hello to data-driven decision making.</p>
          <p>We're committed to providing the best possible service to our customers</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NagAbout;
