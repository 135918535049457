import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Nav, Tab, Tabs, Spinner, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { LocationContext, LinkingContext, NotificationContext } from 'context/Context';
import Forecast from 'components/notaguess/Forecast'
import NagItemsTable from 'components/notaguess/NagItemsTable';
import { isAuthed, triggerLogin, apiServerUrl } from 'config'
import { useNavigate } from "react-router-dom";

const NagForecasts = () => {
  const [loadingPage, setLoading] = useState(true);
  const [selectedLocationHydrated, setSelectedLocationHydrated] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [fullItemName, setFullItemName] = useState(null);
  const { selectedLocation, loading } = useContext(LocationContext);
  const { isLinked, linkedLoading } = useContext(LinkingContext);
  const { showNotification } = useContext(NotificationContext);


  useEffect(() => {
    if (!isAuthed()) {
      triggerLogin();
      return;
    }
    if (!isLinked && !linkedLoading) {
      showNotification("Please connect your Square account by going to the Settings page.", "danger");
    }
  }, [isLinked, linkedLoading]);

  useEffect(() => {
    if (isAuthed() && isLinked) {
      fetchItemsForLocation();
    }
  }, [selectedLocation, isLinked]);

  const fetchItemsForLocation = () => {
    if (!selectedLocation) { return }
    fetch(apiServerUrl() + '/v0/merchant/location/' + selectedLocation.ID + '/items/', {
      'credentials': 'include',
    })
    .then(response => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Not 2xx response", { cause: response });
      } else {
        // got the desired response
        return response.json()
      }
    })
      .then(data => {
        if (data.length < 1) {
          setLoading(false);
          return;
        }
        let newLocation = {
          "info": selectedLocation,
          "items": data
        };
        setSelectedLocationHydrated(newLocation);
        setLocationId(selectedLocation.ID);
        setItemId(data[0].ID);
        setFullItemName(data[0].ItemName + " " + data[0].ItemVariationName);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
        setSelectedLocationHydrated(null)
      });
  };

  const tabSelect = (e, itemId, itemName) => {
    setItemId(itemId);
    setFullItemName(itemName);
  }

  const columns = [
    {
      accessor: 'ItemName',
      Header: 'ItemName',
      Cell: rowData => {
        let fullItemName = rowData.row.original.ItemName + ' - ' + rowData.row.original.ItemVariationName;
        return (
          <Link id={rowData.row.original.ID} to='#!' onClick={(e) => tabSelect(e, rowData.row.original.ID, fullItemName)}>
            {fullItemName}
          </Link>
        )
      }
    },
  ];

  if (isAuthed && isLinked && (loadingPage || loading)) {
    return (
      <Row className="g-3 mb-3">
        <Spinner animation="grow" size="sm" />
      </Row>
    );
  }

  return (
    <>
      <PageHeader
        title="Forecasts"
        description="View forecasts for all your locations and all product variants."
        className="mb-4"
      />
      <Card className='mb-2'>
        <Card.Body className="py-2">
          <Row className="g-3 mb-3">
            {selectedLocationHydrated && selectedLocationHydrated.items &&
              <>
                <Col lg={4}>
                  <Nav variant="pills" className="flex-column">
                    <NagItemsTable columns={columns} locationItems={selectedLocationHydrated.items.filter(i => i.ShouldTrack)} />
                  </Nav>
                </Col>
                <Col lg={8}>
                  <Forecast locationId={locationId} itemId={itemId} fullItemName={fullItemName} showDetailedForecast={true} showDetailsLink={false} showDemoImg={false} />
                </Col>
              </>
            }
            {!selectedLocationHydrated &&
              <div className="alert alert-info" role="alert">Nothing yet</div>
            }
          </Row>

        </Card.Body>
      </Card>
    </>
  );
};

export default NagForecasts;

